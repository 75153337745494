import React from 'react';

const YourLogoIcon = (props) => {
  return (
      <svg
      width={16} // Use responsive width
      height={16} // Use responsive height
      viewBox="0 0 24 24" // Adjust viewBox to fit the entire logo
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
    >
      <path d="M6.002 1.61 0 12.004 6.002 22.39h11.996L24 12.004 17.998 1.61zm1.593 4.084h3.947c3.605 0 6.276 1.695 6.276 6.31 0 4.436-3.21 6.302-6.456 6.302H7.595zm2.517 2.449v7.714h1.241c2.646 0 3.862-1.55 3.862-3.861.009-2.569-1.096-3.853-3.767-3.853z"/>
    </svg>
  );
};

export default YourLogoIcon;
